export const contentConstants = {
  REQUEST_COURSECONTENT_ADD: "REQUEST_COURSECONTENT_ADD",
  RESPONSE_COURSECONTENT_ADD: "RESPONSE_COURSECONTENT_ADD",
  FAILURE_COURSECONTENT_ADD: "FAILURE_COURSECONTENT_ADD",
  REMOVE_COURSE_LIST: "REMOVE_COURSE_LIST",
  REQUEST_COURSECONTENT_FETCH: "REQUEST_COURSECONTENT_FETCH",
  RESPONSE_COURSECONTENT_FETCH: "RESPONSE_COURSECONTENT_FETCH",
  FAILURE_COURSECONTENT_FETCH: "FAILURE_COURSECONTENT_FETCH",
  REQUEST_GENERALCONTENT_ADD: "REQUEST_GENERALCONTENT_ADD",
  RESPONSE_GENERALCONTENT_ADD: "RESPONSE_GENERALCONTENT_ADD",
  FAILURE_GENERALCONTENT_ADD: "FAILURE_GENERALCONTENT_ADD",
  // REQUEST_GENERALCONTENT_FETCH  : 'REQUEST_GENERALCONTENT_FETCH',
  // RESPONSE_GENERALCONTENT_FETCH : 'RESPONSE_GENERALCONTENT_FETCH',
  // FAILURE_GENERALCONTENT_FETCH  : 'FAILURE_GENERALCONTENT_FETCH',
  REQUEST_CONTENT_UPDATE: "REQUEST_CONTENT_UPDATE",
  RESPONSE_CONTENT_UPDATE: "RESPONSE_CONTENT_UPDATE",
  FAILURE_CONTENT_UPDATE: "FAILURE_CONTENT_UPDATE",
  REQUEST_CONTENT_DELETE: "REQUEST_CONTENT_DELETE",
  RESPONSE_CONTENT_DELETE: "RESPONSE_CONTENT_DELETE",
  FAILURE_CONTENT_DELETE: "FAILURE_CONTENT_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
  REQUEST_MASTERCONTENT_ADD: "REQUEST_MASTERCONTENT_ADD",
  RESPONSE_MASTERCONTENT_ADD: "RESPONSE_MASTERCONTENT_ADD",
  FAILURE_MASTERCONTENT_ADD: "FAILURE_MASTERCONTENT_ADD",
  FAILURE_SUBJECT_COURSECONTENT_FETCH: "FAILURE_SUBJECT_COURSECONTENT_FETCH",
  RESPONSE_SUBJECT_COURSECONTENT_FETCH: "RESPONSE_SUBJECT_COURSECONTENT_FETCH",
  REQUEST_SUBJECT_COURSECONTENT_FETCH: "REQUEST_SUBJECT_COURSECONTENT_FETCH",
  REQUEST_ICON_DELETE: "REQUEST_ICON_DELETE",
  RESPONSE_ICON_DELETE: "RESPONSE_ICON_DELETE",
  FAILURE_ICON_DELETE: "FAILURE_ICON_DELETE",
};
