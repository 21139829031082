export const subjectConstants = {
  REQUEST_SUBJECT_ADD: "REQUEST_SUBJECT_ADD",
  RESPONSE_SUBJECT_ADD: "RESPONSE_SUBJECT_ADD",
  FAILURE_SUBJECT_ADD: "FAILURE_SUBJECT_ADD",
  REMOVE_SUBJECT_LIST: "REMOVE_SUBJECT_LIST",
  REQUEST_SUBJECT_FETCH: "REQUEST_SUBJECT_FETCH",
  RESPONSE_SUBJECT_FETCH: "RESPONSE_SUBJECT_FETCH",
  FAILURE_SUBJECT_FETCH: "FAILURE_SUBJECT_FETCH",
  REQUEST_SUBJECT_UPDATE: "REQUEST_SUBJECT_UPDATE",
  RESPONSE_SUBJECT_UPDATE: "RESPONSE_SUBJECT_UPDATE",
  FAILURE_SUBJECT_UPDATE: "FAILURE_SUBJECT_UPDATE",
  REQUEST_SUBJECT_DELETE: "REQUEST_SUBJECT_DELETE",
  RESPONSE_SUBJECT_DELETE: "RESPONSE_SUBJECT_DELETE",
  FAILURE_SUBJECT_DELETE: "FAILURE_SUBJECT_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
  REQUEST_SUBJECT_DELETE_ADD: "REQUEST_SUBJECT_DELETE_ADD",
  RESPONSE_SUBJECT_DELETE_ADD: "RESPONSE_SUBJECT_DELETE_ADD",
};
