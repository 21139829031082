export const programConstants = {
  REQUEST_PROGRAM_ADD: "REQUEST_PROGRAM_ADD",
  RESPONSE_PROGRAM_ADD: "RESPONSE_PROGRAM_ADD",
  FAILURE_PROGRAM_ADD: "FAILURE_PROGRAM_ADD",
  REQUEST_PROGRAM_FETCH: "REQUEST_PROGRAM_FETCH",
  RESPONSE_PROGRAM_FETCH: "RESPONSE_PROGRAM_FETCH",
  FAILURE_PROGRAM_FETCH: "FAILURE_PROGRAM_FETCH",
  REMOVE_PROGRAM_LIST: "REMOVE_PROGRAM_LIST",
  REQUEST_PROGRAM_UPDATE: "REQUEST_PROGRAM_UPDATE",
  RESPONSE_PROGRAM_UPDATE: "RESPONSE_PROGRAM_UPDATE",
  FAILURE_PROGRAM_UPDATE: "FAILURE_PROGRAM_UPDATE",
  REQUEST_PROGRAM_DELETE: "REQUEST_PROGRAM_DELETE",
  RESPONSE_PROGRAM_DELETE: "RESPONSE_PROGRAM_DELETE",
  FAILURE_PROGRAM_DELETE: "FAILURE_PROGRAM_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
};
