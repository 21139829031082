export const videoConstants = {
  REQUEST_VIDEO_ADD: "REQUEST_VIDEO_ADD",
  RESPONSE_VIDEO_ADD: "RESPONSE_VIDEO_ADD",
  FAILURE_VIDEO_ADD: "FAILURE_VIDEO_ADD",
  REMOVE_VIDEO_LIST: "REMOVE_VIDEO_LIST",
  REQUEST_VIDEO_FETCH: "REQUEST_VIDEO_FETCH",
  RESPONSE_VIDEO_FETCH: "RESPONSE_VIDEO_FETCH",
  FAILURE_VIDEO_FETCH: "FAILURE_VIDEO_FETCH",
  REQUEST_VIDEO_UPDATE: "REQUEST_VIDEO_UPDATE",
  RESPONSE_VIDEO_UPDATE: "RESPONSE_VIDEO_UPDATE",
  FAILURE_VIDEO_UPDATE: "FAILURE_VIDEO_UPDATE",
  REQUEST_VIDEO_DELETE: "REQUEST_VIDEO_DELETE",
  RESPONSE_VIDEO_DELETE: "RESPONSE_VIDEO_DELETE",
  FAILURE_VIDEO_DELETE: "FAILURE_VIDEO_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
  REQUEST_SINGLE_VIDEO_DELETE: "REQUEST_SINGLE_VIDEO_DELETE",
  RESPONSE_SINGLE_VIDEO_DELETE: "RESPONSE_SINGLE_VIDEO_DELETE",
  FAILURE_SINGLE_VIDEO_DELETE: "FAILURE_SINGLE_VIDEO_DELETE",
};
