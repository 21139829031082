import { chapterConstants } from "../constants/index";

const initialSettings = {
  contentchapter: [],
  contentchapters: [],
  contentchaptersList: [],
  // generalbookchapter: [],
  // generalbookchaptersList: [],
  chapterLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    // add chapters in coursebook
    case chapterConstants.REQUEST_COURSEBOOK_CHAPTER_ADD:
      return {
        ...state,
        chapterLoading: true,
      };

    case chapterConstants.RESPONSE_COURSEBOOK_CHAPTER_ADD:
      console.log("action is===", action);
      console.log("Action.data", action.data);
      const chapterr =
        action.data && action.data.data && action.data.data.chapter ? action.data.data.chapter : [];
      console.log("chapterr", chapterr);

      return {
        ...state,
        chapterLoading: false,
        contentchapters: [...state.contentchapters, action.data.data.chapter],
        msg: action.data.data.msg,
      };
    // console.log("action is===",action);
    // console.log("Action.data", action.data)
    // const chapter2 =  action.data && action.data.data ? action.data.data : null
    // console.log("chapter",chapter2)
    // let newChapterList = state.contentchaptersList
    // // @ts-ignore
    // newChapterList.push(chapter2)
    // console.log("NewList Chapterboo", newChapterList)

    // return {
    //     ...state,
    //     chapterLoading: false,
    //     // contentchapter: action.data.data.chapter,
    //     contentchaptersList: newChapterList,
    //     // msg: action.data.data.msg
    // }

    case chapterConstants.FAILURE_COURSEBOOK_CHAPTER_ADD:
      return {
        ...state,
        chapterLoading: false,
        msg: action.data.data.msg,
      };

    case chapterConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    case chapterConstants.REMOVE_CHAPTER_LIST:
      return {
        ...state,
        contentchaptersList: [],
        contentchapters: [],
      };

    // Fetch chapters from coursebook Reducers
    case chapterConstants.REQUEST_COURSEBOOK_CHAPTER_FETCH:
      return {
        ...state,
        chapterLoading: true,
      };

    case chapterConstants.RESPONSE_COURSEBOOK_CHAPTER_FETCH:
      console.log("Action.data", action.data);
      const CourseBookChapterList =
        action.data && action.data.data && action.data.data.data ? action.data.data.data : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("ChapterList==>", CourseBookChapterList);
      return {
        ...state,
        chapterLoading: false,
        contentchaptersList: CourseBookChapterList,
        count: counts,
      };

    // add chapters in generalbook
    case chapterConstants.REQUEST_GENERALBOOK_CHAPTER_ADD:
      return {
        ...state,
        chapterLoading: true,
      };

    case chapterConstants.RESPONSE_GENERALBOOK_CHAPTER_ADD:
      console.log("Action.data", action.data);
      const generalbookchapter2 = action.data && action.data.data ? action.data.data : null;
      console.log("chapter", generalbookchapter2);
      let newGeneralBookChapterList = state.contentchaptersList;
      // @ts-ignore
      newGeneralBookChapterList.push(generalbookchapter2);
      console.log("NewList Chapterbook", newGeneralBookChapterList);

      return {
        ...state,
        chapterLoading: false,
        contentchapter: generalbookchapter2,
        contentchaptersList: newGeneralBookChapterList,
      };

    // Fetch chapters from generalbook Reducers
    // case chapterConstants.REQUEST_GENERALBOOK_CHAPTER_FETCH:
    //   return {
    //       ...state,
    //       chapterLoading: true,
    //   }

    // case chapterConstants.RESPONSE_GENERALBOOK_CHAPTER_FETCH:
    //   console.log("Action.data", action.data)
    //   const GeneralBookChapterList = action.data && action.data.data ? action.data.data : []
    //   console.log("ChapterList", GeneralBookChapterList)
    //   return {
    //       ...state,
    //       chapterLoading: false,
    //       generalbookchaptersList: GeneralBookChapterList
    //   }

    // Reducer for update Chapter
    case chapterConstants.REQUEST_CHAPTER_UPDATE:
      return {
        ...state,
        chapterLoading: true,
      };
    case chapterConstants.RESPONSE_CHAPTER_UPDATE:
      const updateChapterMeta = action.data;
      console.log("Action data", updateChapterMeta);

      console.log("Update Chapter id", action.data.data.chapter._id);
      let beforeEditChapterList = state.contentchaptersList;
      let afterEditChapterList: any[] = [];

      beforeEditChapterList.map((chapter) => {
        console.log("id", action.data.data.chapter._id);
        //@ts-ignore
        if (chapter._id !== action.data.data.chapter._id) {
          afterEditChapterList.push(chapter);
        } else {
          afterEditChapterList.push(action.data.data.chapter);
        }
        return console.log(chapter);
      });

      return {
        ...state,
        chapterLoading: false,
        contentchaptersList: afterEditChapterList,
        contentchapter: updateChapterMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete Chapter
    case chapterConstants.REQUEST_CHAPTER_DELETE:
      return {
        ...state,
        chapterLoading: true,
      };
    case chapterConstants.RESPONSE_CHAPTER_DELETE:
      const deleteChapterMeta = action.data;
      console.log("Action data", deleteChapterMeta);
      console.log("Action", action);
      console.log("Delete Content id", action.data.data.data.chapter._id);
      let beforeDeleteChapterList = state.contentchaptersList;
      let beforeDeleteChapters = state.contentchapters;
      //@ts-ignore
      console.log("chapterlist", beforeDeleteChapterList);
      let afterDeleteChapterList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.chapter._id
      ) {
        //@ts-ignore
        beforeDeleteChapterList = state.contentchaptersList.filter((chapter) => chapter._id!== action.data.data.data.chapter._id)
        //@ts-ignore
        beforeDeleteChapters = state.contentchapters.filter((chapter) => chapter._id!== action.data.data.data.chapter._id)
      }

      return {
        ...state,
        chapterLoading: false,
        contentchapters: beforeDeleteChapters,
        contentchaptersList: afterDeleteChapterList,
      };

    default:
      return state;
  }
}
