export const userConstants = {
  REQUEST_USER_ADD: "REQUEST_USER_ADD",
  RESPONSE_USER_ADD: "RESPONSE_USER_ADD",
  FAILURE_USER_ADD: "FAILURE_USER_ADD",
  REMOVE_USER_LIST: "REMOVE_USER_LIST",
  REQUEST_USER_FETCH: "REQUEST_USER_FETCH",
  RESPONSE_USER_FETCH: "RESPONSE_USER_FETCH",
  FAILURE_USER_FETCH: "FAILURE_USER_FETCH",
  REQUEST_USER_UPDATE: "REQUEST_USER_UPDATE",
  RESPONSE_USER_UPDATE: "RESPONSE_USER_UPDATE",
  FAILURE_USER_UPDATE: "FAILURE_USER_UPDATE",
  REQUEST_USER_DELETE: "REQUEST_USER_DELETE",
  RESPONSE_USER_DELETE: "RESPONSE_USER_DELETE",
  FAILURE_USER_DELETE: "FAILURE_USER_DELETE",
  REQUEST_USER_SIGNIN: "REQUEST_USER_SIGNIN",
  RESPONSE_USER_SIGNIN: "RESPONSE_USER_SIGNIN",
  FAILURE_USER_SIGNIN: "FAILURE_USER_SIGNIN",
  USER_DO_LOGOUT: "USER_DO_LOGOUT",
  CLEAR_MSG: "CLEAR_MSG",
  REQUEST_MULTIPLE_USER_ADD: "REQUEST_MULTIPLE_USER_ADD",
  RESPONSE_MULTIPLE_USER_ADD: "RESPONSE_MULTIPLE_USER_ADD",
  FAILURE_MULTIPLE_USER_ADD: "FAILURE_MULTIPLE_USER_ADD",
  WRONG_USER_SIGNIN_CREDENTIALS: "WRONG_USER_SIGNIN_CREDENTIALS",
};
