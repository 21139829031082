export const boardConstants = {
  REQUEST_BOARD_ADD: "REQUEST_BOARD_ADD",
  RESPONSE_BOARD_ADD: "RESPONSE_BOARD_ADD",
  FAILURE_BOARD_ADD: "FAILURE_BOARD_ADD",
  REQUEST_BOARD_FETCH: "REQUEST_BOARD_FETCH",
  RESPONSE_BOARD_FETCH: "RESPONSE_BOARD_FETCH",
  FAILURE_BOARD_FETCH: "FAILURE_BOARD_FETCH",
  REMOVE_BOARD_LIST: "REMOVE_BOARD_LIST",
  REQUEST_BOARD_UPDATE: "REQUEST_BOARD_UPDATE",
  RESPONSE_BOARD_UPDATE: "RESPONSE_BOARD_UPDATE",
  FAILURE_BOARD_UPDATE: "FAILURE_BOARD_UPDATE",
  REQUEST_BOARD_DELETE: "REQUEST_BOARD_DELETE",
  RESPONSE_BOARD_DELETE: "RESPONSE_BOARD_DELETE",
  FAILURE_BOARD_DELETE: "FAILURE_BOARD_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
};
