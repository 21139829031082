import * as React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
// import { darken } from "polished";

import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import { Power } from "react-feather";
import { logout } from "../redux/actions/userActions";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${props => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;

//   &:hover {
//     background-color: ${props => darken(0.05, props.theme.header.background)};
//   }

//   ${props => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${props => props.theme.header.search.color};
//     padding-top: ${props => props.theme.spacing(2.5)}px;
//     padding-right: ${props => props.theme.spacing(2.5)}px;
//     padding-bottom: ${props => props.theme.spacing(2.5)}px;
//     padding-left: ${props => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

// const Flag = styled.img`
//   border-radius: 50%;
//   width: 22px;
//   height: 22px;
// `;


function UserMenu(props:any) {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLogout = () => {
    //@ts-ignore
    props.logout();
  }

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>
          Profile
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const Header = (props: any) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={props.onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          {/* <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder="Search topics" />
            </Search>
          </Grid> */}
          <Grid item xs />
          <Grid item>
            <UserMenu 
            //@ts-ignore
            logout={props.logout} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.userReducer.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Header));

