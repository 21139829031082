export const bookConstants = {
    REQUEST_COURSEBOOK_ADD      : 'REQUEST_COURSEBOOK_ADD',
    RESPONSE_COURSEBOOK_ADD     : 'RESPONSE_COURSEBOOK_ADD',
    FAILURE_COURSEBOOK_ADD      : 'FAILURE_COURSEBOOK_ADD',
    REQUEST_COURSEBOOK_FETCH    : 'REQUEST_COURSEBOOK_FETCH',
    RESPONSE_COURSEBOOK_FETCH   : 'RESPONSE_COURSEBOOK_FETCH',
    FAILURE_COURSEBOOK_FETCH    : 'FAILURE_COURSEBOOK_FETCH',
    REQUEST_COURSEBOOK_DELETE   : 'REQUEST_COURSEBOOK_DELETE',
    RESPONSE_COURSEBOOK_DELETE  : 'RESPONSE_COURSEBOOK_DELETE',
    FAILURE_COURSEBOOK_DELETE   : 'FAILURE_COURSEBOOK_DELETE',
    REQUEST_GENERALBOOK_ADD     : 'REQUEST_GENERALBOOK_ADD',
    RESPONSE_GENERALBOOK_ADD    : 'RESPONSE_GENERALBOOK_ADD',
    FAILURE_GENERALBOOK_ADD     : 'FAILURE_GENERALBOOK_ADD',
    REQUEST_GENERAL_BOOK_FETCH  : 'REQUEST_GENERAL_BOOK_FETCH',
    RESPONSE_GENERAL_BOOK_FETCH : 'RESPONSE_GENERAL_BOOK_FETCH',
    FAILURE_GENERAL_BOOK_FETCH  : 'FAILURE_GENERAL_BOOK_FETCH',
    REQUEST_GENERALBOOK_DELETE  : 'REQUEST_GENERALBOOK_DELETE',
    RESPONSE_GENERALBOOK_DELETE : 'RESPONSE_GENERALBOOK_DELETE',
    FAILURE_GENERALBOOK_DELETE  : 'FAILURE_GENERALBOOK_DELETE'
    
};
