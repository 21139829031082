export const educatorConstants = {
  REQUEST_EDUCATOR_ADD: "REQUEST_EDUCATOR_ADD",
  RESPONSE_EDUCATOR_ADD: "RESPONSE_EDUCATOR_ADD",
  FAILURE_EDUCATOR_ADD: "FAILURE_EDUCATOR_ADD",
  REQUEST_EDUCATOR_FETCH: "REQUEST_EDUCATOR_FETCH",
  RESPONSE_EDUCATOR_FETCH: "RESPONSE_EDUCATOR_FETCH",
  FAILURE_EDUCATOR_FETCH: "FAILURE_EDUCATOR_FETCH",
  REMOVE_EDUCATOR_LIST: "REMOVE_EDUCATOR_LIST",
  REQUEST_EDUCATOR_UPDATE: "REQUEST_EDUCATOR_UPDATE",
  RESPONSE_EDUCATOR_UPDATE: "RESPONSE_EDUCATOR_UPDATE",
  FAILURE_EDUCATOR_UPDATE: "FAILURE_EDUCATOR_UPDATE",
  REQUEST_EDUCATOR_DELETE: "REQUEST_EDUCATOR_DELETE",
  RESPONSE_EDUCATOR_DELETE: "RESPONSE_EDUCATOR_DELETE",
  FAILURE_EDUCATOR_DELETE: "FAILURE_EDUCATOR_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
};
