import { userConstants } from "../constants/index";
let data = localStorage.getItem("thup");
//@ts-ignore
data = data && data.length ? JSON.parse(atob(data)) : {};
console.log("data in user reducer", data);
const initialSettings = {
  usersList: [],
  usersLoading: false,
  //@ts-ignore
  user: data && data.user && data.user._id ? data.user : {},
  //@ts-ignore
  token: data && data.token ? data.token : null,
  //@ts-ignore
  isLoggedIn: data && data.token ? true : false,
  msg: null,
  new_error: "",
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action: any) {
  switch (action.type) {
    case userConstants.REQUEST_USER_ADD:
      return {
        ...state,
        usersLoading: true,
      };

    case userConstants.RESPONSE_USER_ADD:
      console.log("Action.data", action.data.data.msg);
      return {
        ...state,
        usersLoading: false,
        msg: action.data.data.msg,
      };

    case userConstants.RESPONSE_MULTIPLE_USER_ADD:
      console.log("Action.data", action.data.data.msg);
      return {
        ...state,
        usersLoading: false,
        msg: action.data.data.msg,
      };

    case userConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    case userConstants.USER_DO_LOGOUT:
      localStorage.removeItem("rememberMe");
      return {
        ...state,
        isLoggedIn: false,
        usersLoading: false,
        user: {},
        authToken: null,
      };

    case userConstants.REQUEST_USER_SIGNIN:
      return {
        ...state,
        usersLoading: true,
        isLoading: true,
        new_error: null,
      };

    case userConstants.RESPONSE_USER_SIGNIN:
      console.log("RESPONSE_USER_SIGNIN", action.data.data);
      return {
        ...state,
        user: action.data.response.user,
        token: action.data.response.token,
        isLoggedIn: !!action.data.response.token,
        usersLoading: false,
        isLoading: false,
      };

    case userConstants.WRONG_USER_SIGNIN_CREDENTIALS:
      console.log("Output in user Reducer", action.data);
      return {
        ...state,
        new_error: action.data,
        isLoading: false,
      };

    // Fetch Users
    case userConstants.REQUEST_USER_FETCH:
      return {
        ...state,
        usersLoading: true,
      };

    case userConstants.RESPONSE_USER_FETCH:
      console.log("Action Data", action.data);
      const usersList =
        action.data && action.data.data && action.data.data.user && action.data.data.user.length
          ? action.data.data.user
          : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("usersList", usersList);
      return {
        ...state,
        usersLoading: false,
        usersList: usersList,
        count: counts,
      };

    // Reducer for delete User
    case userConstants.REQUEST_USER_DELETE:
      return {
        ...state,
        usersLoading: true,
      };
    case userConstants.RESPONSE_USER_DELETE:
      let beforeDeleteUsersList = state.usersList;
      //@ts-ignore
      console.log("beforeDeleteUsersList", beforeDeleteUsersList);
      let afterDeleteUserList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.user._id
      ) {
        if (beforeDeleteUsersList.length) {
          beforeDeleteUsersList.map((bo: any[]) => {
            console.log("user", bo);
            //@ts-ignore
            if (bo._id !== action.data.data.data.user._id) {
              afterDeleteUserList.push(bo);
            }
            return true;
          });
        }
      }

      return {
        ...state,
        usersLoading: false,
        usersList: afterDeleteUserList,
      };

    default:
      return state;
  }
}
