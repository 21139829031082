import React from "react";
import {
  Sliders,
} from "react-feather";
import async from "../components/Async";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
// const Page404 = async(() => import("../pages/auth/Page404"));
// const Page500 = async(() => import("../pages/auth/Page500"));
const Analytics = async(() => import("../pages/analytics"));
// User components
//@ts-ignore
const UploadCSV = async(() => import("../pages/users/importCSV"));
const AddUser = async(() => import("../pages/users/Add/AddUser"));
const UsersList = async(() => import("../pages/users/View/usersList"));
// Boards components
// @ts-ignore
const AddBoard = async(() => import("../pages/boards/Add/AddBoard"));
const EditBoard = async(() => import("../pages/boards/Edit/EditBoard"));
const ViewBoard = async(() => import("../pages/boards/View/ViewBoard"));

// Educators components
//@ts-ignore
const AddEducator = async(() => import("../pages/educators/Add/AddEducator"));
const EditEducator = async(() => import("../pages/educators/Edit/EditEducator"));
const ViewEducator = async(() => import("../pages/educators/View/ViewEducator"));

// Programs components
//@ts-ignore
const AddProgram = async(() => import("../pages/programs/Add/AddProgram"));
//@ts-ignore
const EditProgram = async(() => import("../pages/programs/Edit/EditProgram"));
//@ts-ignore
const ViewProgram = async(() => import("../pages/programs/View/ViewProgram"))

// Classes components
//@ts-ignore
const AddClass = async(() => import("../pages/classes/Add/AddClass"));
const EditClass = async(() => import("../pages/classes/Edit/EditClass"));
const ViewClass = async(() => import("../pages/classes/View/ViewClass"));

// Subjects components
//@ts-ignore
const AddSubject = async(() => import("../pages/subjects/Add/AddSubject"));
const EditSubject = async(() => import("../pages/subjects/Edit/EditSubject"));
const ViewSubject = async(() => import("../pages/subjects/View/ViewSubject"));

// Books components
// @ts-ignore
const AddBook = async(() => import("../pages/books/Add/AddBook"));
const ViewBook = async(() => import("../pages/books/View/ViewBook"));

//Chapter components
//@ts-ignore
const AddChapter = async(() => import("../pages/chapters/Add/AddChapter"));
const EditChapter = async(() => import("../pages/chapters/Edit/EditChapter"));
const ViewChapter = async(() => import("../pages/chapters/View/ViewChapter"));

//Video components
//@ts-ignore
const AddVideo = async(() => import("../pages/videos/Add/AddVideo"));
const EditVideo = async(() => import("../pages/videos/Edit/EditVideo"));
const ViewVideo = async(() => import("../pages/videos/View/ViewVideo"));


// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
// const Presentation = async(() => import("../pages/docs/Presentation"));
const AddContent = async(() => import("../pages/content/Add/AddContent"));
const AddMasterContent = async(() => import("../pages/content/Add/AddMasterContent"));
const EditContent = async(() => import("../pages/content/Edit/EditContent"));
const ViewContent = async(() => import("../pages/content/View/ViewContent"));

//appconfig component
//@ts-ignore
const appSettings = async(() => import("../pages/settings/UploadIcon"));

// const dashboardsRoutes = {
//   id: "Dashboard",
//   path: "/dashboard",
//   header: "Pages",
//   icon: <Sliders />,
//   containsHome: true,
//   children: [
//     {
//       path: "/dashboard/default",
//       name: "Default",
//       component: Default
//     },
//     {
//       path: "/dashboard/analytics",
//       name: "Analytics",
//       component: Analytics
//     }
//   ],
//   component: null
// };

const usersRoutes = {
  id: "Manage User",
  path: "/user",
  icon: <Sliders />,
  children: [
    {
      path: "/user/add",
      name: "Create User",
      component: AddUser
    },
    {
      path: "/user/upload",
      name: "Upload CSV",
      component: UploadCSV
    },
    {
      path: "/user/list",
      name: "User List",
      component: UsersList
    },
  ],
  component: null
};

const boardsRoutes = {
  id: "Manage University/Board",
  path: "/educatorType",
  icon: <Sliders />,
  children: [
    {
      path: "/educatorType/add",
      name: "Create University/Board",
      component: AddBoard
    },
    {
      path: "/educatorType/view",
      name: "View University/Board",
      component: ViewBoard
    }
  ],
  component: null
};

const EditBoardRoutes = {
  id: "Manage Board",
  path: "/educatorType/Edit",
  children: null,
  component: EditBoard
};

const educatorsRoutes = {
  id: "Manage Educator",
  path: "/educator",
  icon: <Sliders />,
  children: [
    {
      path: "/educator/add",
      name: "Create Educator",
      component: AddEducator
    },
    {
      path: "/educator/view",
      name: "View Educator",
      component: ViewEducator
    }
  ],
  component: null
};

const EditEducatorRoutes = {
  id: "Manage Educator",
  path: "/educator/Edit",
  children: null,
  component: EditEducator
};

const programsRoutes = {
  id: "Manage Program",
  path: "/program",
  icon: <Sliders />,
  children: [
    {
      path: "/program/add",
      name: "Create Program",
      component: AddProgram
    },
    {
      path: "/program/view",
      name: "View Program",
      component: ViewProgram
    }
  ],
  component: null
};

const EditProgramRoutes = {
  id: "Manage Program",
  path: "/program/Edit",
  children: null,
  component: EditProgram
};

const classesRoutes = {
  id: "Manage Class",
  path: "/class",
  icon: <Sliders />,
  children: [
    {
      path: "/class/add",
      name: "Create Class",
      component: AddClass
    },
    {
      path: "/class/view",
      name: "View Class",
      component: ViewClass
    }
  ],
  component: null
};

const EditClassRoutes = {
  id: "Manage Class",
  path: "/class/Edit",
  children: null,
  component: EditClass
};

const subjectsRoutes = {
  id: "Manage Subject",
  path: "/subject",
  icon: <Sliders />,
  children: [
    {
      path: "/subject/add",
      name: "Create Subject",
      component: AddSubject
    },
    {
      path: "/subject/view",
      name: "View Subject",
      component: ViewSubject
    }
  ],
  component: null
};

const EditSubjectRoutes = {
  id: "Manage Subject",
  path: "/subject/Edit",
  children: null,
  component: EditSubject
};

const booksRoutes = {
  id: "Manage Book",
  path: "/book",
  icon: <Sliders />,
  children: [
    {
      path: "/book/add",
      name: "Create Book",
      component: AddBook
    },
    {
      path: "/book/view",
      name: "View Book",
      component: ViewBook
    }
  ],
  component: null
};

const chaptersRoutes = {
  id: "Manage Chapter",
  path: "/chapter",
  icon: <Sliders />,
  children: [
    {
      path: "/chapter/add",
      name: "Create Chapter",
      component: AddChapter
    },
    {
      path: "/chapter/view",
      name: "View Chapter",
      component: ViewChapter
    }
  ],
  component: null
};

const EditChapterRoutes = {
  id: "Manage Chapter",
  path: "/chapter/Edit",
  children: null,
  component: EditChapter
};

const EditContentRoutes = {
  id: "Manage Book",
  path: "/content/edit",
  children: null,
  component: EditContent
};

const ContentRoutes = {
  id: "Manage Book",
  path: "/content",
  icon: <Sliders />,
  children: [
    {
      path: "/content/masteradd",
      name: "Create Master Book",
      component: AddMasterContent
    },
    {
      path: "/content/add",
      name: "Add Book",
      component: AddContent
    },
    {
      path: "/content/view",
      name: "View Book",
      component: ViewContent
    }
  ],
  component: null
};

const videosRoutes = {
  id: "Manage Video",
  path: "/video",
  icon: <Sliders />,
  children: [
    {
      path: "/video/add",
      name: "Create Video",
      component: AddVideo
    },
    {
      path: "/video/view",
      name: "View Video",
      component: ViewVideo
    }
  ],
  component: null
};

const EditVideoRoutes = {
  id: "Manage Video",
  path: "/video/Edit",
  children: null,
  component: EditVideo
};


const AppConfigRoutes = {
  id: "AppConfig",
  path: "/config",
  icon: <Sliders />,
  containsHome: false,
  component: appSettings,
  children: null,
}

// const pagesRoutes = {
//   id: "Pages",
//   path: "/pages",
//   icon: <Layout />,
//   children: [
//     {
//       path: "/pages/blank",
//       name: "Blank Page",
//       component: Blank
//     }
//   ],
//   component: null
// };

const authRoutes = {
  id: "Auth",
  path: "/auth",
  // icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
//     {
//       path: "/auth/sign-up",
//       name: "Sign Up",
//       component: SignUp
//     },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
//     {
//       path: "/auth/404",
//       name: "404 Page",
//       component: Page404
//     },
//     {
//       path: "/auth/500",
//       name: "500 Page",
//       component: Page500
//     }
  ],
  component: null
};

const analyticsRoutes = {
  id: "Analytics",
  path: "/",
  // header: "Analytics",
  // icon: <Monitor />,
  component: Analytics,
  children: null
};

// const presentationRoutes = {
//   id: "Presentation",
//   path: "/",
//   // header: "Docs",
//   // icon: <Monitor />,
//   component: Presentation,
//   children: null
// };

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  // dashboardsRoutes,
  analyticsRoutes,
  usersRoutes,
  boardsRoutes,
  educatorsRoutes,
  programsRoutes,
  classesRoutes,
  subjectsRoutes,
  booksRoutes,
  EditBoardRoutes,
  EditEducatorRoutes,
  EditProgramRoutes,
  EditClassRoutes,
  EditSubjectRoutes,
  EditChapterRoutes,
  ContentRoutes,
  chaptersRoutes,
  AppConfigRoutes,
  
  // pagesRoutes,
  // presentationRoutes,
  EditContentRoutes,
  privateRoutes,
  videosRoutes,
  EditVideoRoutes
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  // dashboardsRoutes,
  analyticsRoutes,
  usersRoutes,
  boardsRoutes,
  educatorsRoutes,
  programsRoutes,
  EditBoardRoutes,
  EditEducatorRoutes,
  EditProgramRoutes,
  EditClassRoutes,
  EditSubjectRoutes,
  EditContentRoutes,
  EditChapterRoutes,
  classesRoutes,
  subjectsRoutes,
  // booksRoutes,
  ContentRoutes,
  chaptersRoutes,
  videosRoutes,
  AppConfigRoutes,
  
  // pagesRoutes,
  // presentationRoutes,
];
